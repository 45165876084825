// import CONFIG from './activity-stream.config.js'

import templateUrl from './activity-stream.html'
import activityIntroMessageTemplate from './activity-intro-message.html'

import './activity-stream.scss'
const ATTACHMENT_VERB = 'create_attachment'

const ActivityStreamComponent = {
  bindings: {
    activities: '<',
    task: '<'
  },
  require: {
    AppCtrl: '^^app'
  },
  templateUrl,
  controller: class ActivityStream {
    constructor ($scope, $stateParams, $window, $timeout, $filter, $anchorScroll, Storage, ProjectService, UserService, ActivityStreamService, Configuration, EventBusService, AttachmentsService, CommentService) {
      'ngInject'
      this._identify = 'ActivityStreamComponent'
      this.$scope = $scope
      this.$stateParams = $stateParams
      this.$window = $window
      this.$timeout = $timeout
      this.$filter = $filter
      this.$anchorScroll = $anchorScroll
      this.Storage = Storage
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.ActivityStreamService = ActivityStreamService
      this.Configuration = Configuration
      this.EventBusService = EventBusService
      this.AttachmentsService = AttachmentsService
      this.CommentService = CommentService

      this.commentFormHidden = this.Storage.get('activites--commentFormHidden') || false
      // this.scrollOffset = this.Configuration.isBuild ? 80 : 125


      this.isLoading = false
      this.filterData = {
        includePublic: false,
        commentsOnly: false,
        search: ''
      }
      this.isSearchActive = false
      this.onFirstLoadScrollToBottom = true


      // --- TODO: move to service or class
      // TODO: add throthling of events
      // Scroll glue
      this.scrollGlue = {
        isActive: UserService.isExpert(), // enabled only for experts // if true it will scroll down on init // TODO: make this a setting
        isAttached: () => {
          const scrollContainer = this.AppCtrl.$element // jQuery object
          const containerScrollPosition = scrollContainer[0].scrollTop
          const containerScrollHeight = scrollContainer[0].scrollHeight
          const containerClientHeight = scrollContainer[0].clientHeight
          const result = containerScrollHeight - containerScrollPosition === containerClientHeight
          return result
        },
        scroll: () => {
          if (this.AppCtrl.$element[0] && typeof this.AppCtrl.$element[0].scrollTo === 'function') {
            this.AppCtrl.$element[0].scrollTo(0, this.AppCtrl.$element[0].scrollHeight)
          }
        }
      }

      this.onScroll = () => {
        // Ensures scroll after angular template digest
        this.$scope.$evalAsync(() => {
          this.scrollGlue.isActive = this.scrollGlue.isAttached()
        })
      }

      this.scrollIfGlued = () => {
        if (this.scrollGlue.isActive && !this.scrollGlue.isAttached()) {
          // Ensures scroll after angular template digest
          this.$scope.$evalAsync(() => {
            this.scrollGlue.scroll()
          })
        }
      }
      // ---
    }
    get pagination () {
      return this.ActivityStreamService.pagination
    }
    get activityList () {
      return this.ActivityStreamService.activityList
    }
    async $onInit () {
      console.log('[ActivityStreamComponent] > $scope', this.$scope)
      this.activityIntroMessageTemplate = activityIntroMessageTemplate

      // Process pagination from URL
      console.log('this.$stateParams', this.$stateParams)
      const config = {
        params: {
          page: this.$stateParams.page ? this.$stateParams.page : 'last'
        }
      }

      await this.loadActivitiesList(config)
      console.log('[ActivityStream] > $onInit', this.pagination.pageCount, this.activityList)





      // --- Activity related events subscription ---
      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection('legacy')

      // attachment-created
      this.subscriptionCollection.subscribe('attachment-created', (event) => {
        console.log('[ActivityStream] > on > attachment-created', event)
        // Add new activity attachment
        this.ActivityStreamService.addActivity(event.data)
      })

      // attachment-destroyed
      this.subscriptionCollection.subscribe('attachment-destroyed', (event) => {
        console.log('[ActivityStream] > on > attachment-destroyed', event)
        // Add new activity attachment
        this.ActivityStreamService.addActivity(event.data)
      })
      // ---

      const vm = this
      this.expirationConfig = {
        expiresAt: null,
        timeoutPromise: null,
        callback: () => {
          vm.updateActivityAttachmentsUrls()
        }
      }

      this.updateExpirationConfig()
      this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig, 'activities')


      // Scroll to bottom on load
      // TODO: add local storage setting per user for this functionality
      if (this.onFirstLoadScrollToBottom) {
        this.$scope.$evalAsync(() => {
          this.$anchorScroll('activity-list--bottom')
        })
      }

      // --- TODO: move to service or class
      // Scroll glue
      this.unbindScrollGlue = this.$scope.$watch(this.scrollIfGlued)
      this.AppCtrl.$element[0].addEventListener('resize', this.scrollIfGlued, false)
      this.AppCtrl.$element[0].addEventListener('scroll', this.onScroll, false)
      // ---
    }

    // --- TODO: move to service or class
    // Scroll glue
    $onDestroy () {
      this.AppCtrl.$element[0].removeEventListener('resize', this.scrollIfGlued)
      this.AppCtrl.$element[0].removeEventListener('scroll', this.onScroll)

      if (this.subscriptionCollection) {
        this.subscriptionCollection.unsubscribeAll()
      }
      this.AttachmentsService.cancelTimeoutUpdateAttachmentsData(this.expirationConfig)

      console.log('unbindScrollGlue', this.unbindScrollGlue)
      if (this.unbindScrollGlue) {
        this.unbindScrollGlue()
      }
    }
    // ---


    async loadActivitiesList (config) {
      this.isLoading = true
      await this.ActivityStreamService.getActivities({
        projectId: this.task.id,
        pagination: this.pagination,
        filterData: this.filterData,
        ...config
      })
        .then(response => {
          console.log('[ActivityStreamComponent] > loadActivitiesList', config, response)
          // this.activityList = response
          this.isSearchActive = this.filterData.search && this.filterData.search !== ''
        })
        .catch(err => {
          console.log('[ActivityStream] > getActivityList > err', err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    updateExpirationConfig () {
      // find first attachment activity and use its expiry date for handling expiration url
      if (this.activityList) {
        const activity = this.activityList.find(activity => activity.verb === ATTACHMENT_VERB && !activity.trackable.deleted)
        if (activity) {
          this.expirationConfig.expiresAt = activity.trackable.expiresAt
        } else {
          this.expirationConfig.expiresAt = null // null value will use default timeout
        }
      }
    }
    updateActivityAttachmentsUrls () {
      if (this.activityList) {
        const activitiesAttachments = this.activityList.filter(activity => activity.verb === ATTACHMENT_VERB && !activity.trackable.deleted)
        const ids = activitiesAttachments.map(activity => activity.trackable.id)

        if (ids) {
          this.AttachmentsService.getAttachmentList(this.task.id, { shallow: true, ids: ids })
            .then(response => {
              console.log('[ActivityStream] > timeoutUpdateAttachmentsData > response', response)
              if (response) {
                response.forEach(updatedAttachment => {
                  const activityAttachment = activitiesAttachments.find(activity => activity.trackable.id === updatedAttachment.id)
                  if (activityAttachment) {
                    // activityAttachment.trackable.url = updatedAttachment.url
                    Object.assign(activityAttachment.trackable, updatedAttachment)
                    console.log('[ActivityStream] > timeoutUpdateAttachmentsData > update attachment url', activityAttachment)
                  }
                })
              }

              // update config with new data
              this.updateExpirationConfig()
              this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig, 'activities')
            })
        } else {
          // update config with new data if attachments did not exist initially however they were added after or set it to default if there is still no attachments in activities
          this.updateExpirationConfig()
          this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig, 'activities')
        }
      }
    }

    // get numPublicActivities () {
    //   return this.publicOffset > -1 ? this.publicOffset : 0
    // }

    // get numComments () {
    //   if (!this.activityList) {
    //     return 0
    //   }
    //   return this.activityList.filter(activity => activity.verb === CONFIG.commentVerb).length
    // }

    get areFiltersActive () {
      return this.filterData.search ||
        this.filterData.commentsOnly ||
        this.filterData.includePublic
    }

    get showTogglePublic () {
      return this.ProjectService.isNotInState(this.task, [
        'created',
        'published',
        'canceled',
        'estimated',
        'withheld'
      ])
    }

    onPageChangeHandler (page) {
      console.log('[ActivityStreamComponent] > onPageChangeHandler', page)
      this.loadActivitiesList()
    }

    onChangeItemsPerPage () {
      // this.pagination.currentPage = this.pagination.pageCount - 1
      this.Storage.set('activites--items-per-page', this.pagination.itemsPerPage)
      this.loadActivitiesList()
    }

    showPreviousPublic () {
      this.filterData.includePublic = true
      this.loadActivitiesList()
    }

    hidePreviousPublic () {
      this.filterData.includePublic = false
      this.loadActivitiesList()
    }

    addNewHandler (newActivity) {
      const processActivity = this.ActivityStreamService.processActivity(newActivity)
      this.ActivityStreamService.addActivity(processActivity)
    }

    async searchComments () {
      // if (!this.isSearchActive && this.filterData.search.length < 3) {
      //   // prevent search if not enough characters
      //   return
      // }

      if (this.filterData.search === '') {
        this.clearSearchComments()
      }
      await this.loadActivitiesList({ params: { page: 'last' } })

      if (this.filterData.search.length >= 3) {
        this.scrollGlue.isActive = false // disable scroll glue for comment search
      }
    }

    clearSearchComments () {
      this.filterData.search = ''
      this.isSearchActive = false
      this.loadActivitiesList({ params: { page: 'last' } })
    }


    toggleCommentForm () {
      this.commentFormHidden = !this.commentFormHidden
      this.Storage.set('activites--commentFormHidden', this.commentFormHidden)
    }

    get noActivitiesInListMessage () {
      if (this.areFiltersActive) {
        return 'No results found.'
      }
      // Default response currently duplicates function with the intro message - after rework of intro message either remove it or merge them together
      // return 'Currently there are no activites. You can post a comment with form below.'
    }
  }
}

export default ActivityStreamComponent
