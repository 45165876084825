import './comment-templates.scss'

const CommentTemplateViewComponent = {
  bindings: {
    template: '<',
    onApply: '&'
  },
  template: `
    
    <div class="sticky-nav">
      <a href="" ng-click="$ctrl.scrollTo('template-overview')">Overview</a>
      <a href="" ng-click="$ctrl.scrollTo('template-resources')" ng-if="$ctrl.template.resources">Resources</a>
      <a href="" ng-repeat="variant in $ctrl.template.variants"
         ng-click="$ctrl.scrollTo('template-variant-'+$index)">
        {{ variant.label }}
      </a>
    </div>

   
    <div class="mb-8s">
      <h3 id="template-overview" class="scroll-intoview-anchor">Overview</h3>
      <div class="mb-4s">Template: <strong class="mt-0 mb-4s">{{$ctrl.template.label}}</strong></div>
      <div class="mb-4s">Phase: <strong class="mt-0 mb-4s">{{$ctrl.template.phase}}</strong></div>
      <div class="mb-4s">Category: <strong class="mt-0 mb-4s">{{$ctrl.template.category}}</strong></div>
      <div ng-bind-html="$ctrl.template.overview | markup | linksNewTab | trustAsHtml" ></div>
    </div>
    <div class="mb-8s">
    <h3 id="template-resources" class="scroll-intoview-anchor">Resources</h3>
      <div ng-if="$ctrl.template.resources"  ng-bind-html="$ctrl.template.resources | markup | linksNewTab | trustAsHtml" ></div>
      <div ng-if="!$ctrl.template.resources">N/A</div>
    </div>
    <div class="mb-8s">
      <h3 id="template-variants">Variants</h3>
      <div class="mb-8s" ng-repeat="variant in $ctrl.template.variants track by $index">
        <div id="template-variant-{{$index}}" class="font-bold scroll-intoview-anchor">{{ variant.label }}</div>
        <div class="template-variant" ng-bind-html="variant.content | markup | linksNewTab | trustAsHtml" style="height: 500px;"></div>
        <cdbl-button class="button-compact" ng-click="$ctrl.apply(variant)">Insert</cdbl-button>
      </div>
    </div>
  `,
  controller: class CommentTemplateViewComponent {
    constructor (Storage, UserService, ProjectService, ModalService, $timeout, EventEmitter) {
      'ngInject'
      this._identify = 'CommentTemplateViewComponent'
      this.Storage = Storage
      this.UserService = UserService
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.colors = [
        { 'name': 'Soft Beige', 'hex': '#f5f5dc' },
        { 'name': 'Pale Blue', 'hex': '#e6f7ff' },
        { 'name': 'Mint Green', 'hex': '#e0f7f3' },
        { 'name': 'Light Gray', 'hex': '#f2f2f2' },
        { 'name': 'Lavender', 'hex': '#f3e7f3' },
        { 'name': 'Light Peach', 'hex': '#ffe5b4' },
        { 'name': 'Pastel Yellow', 'hex': '#ffffe0' },
        { 'name': 'Powder Blue', 'hex': '#b0e0e6' },
        { 'name': 'Light Sage Green', 'hex': '#d8e2d3' },
        { 'name': 'Pale Coral', 'hex': '#f8d7d2' }
      ]
    }

    $onInit () {

    }

    $onChanges (changesObj) {
      if (changesObj.template) {
        console.log('Input value changed:', changesObj.template.currentValue)
        // You can now perform an action based on the new value of inputValue
        this.scrollTo('template-overview')
      }
    };

    scrollTo (id) {
      console.log('🚀 ~ CommentTemplateViewComponent ~ scrollTo ~ id:', id)
      this.$timeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }

    apply (variant) {
      this.onApply(this.EventEmitter({
        template: this.template,
        variant: variant
      }))
    }
  }
}

export default CommentTemplateViewComponent
