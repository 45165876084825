import ReferredPartnerNoticeComponent from './referred-partner-notice.component'
import CommentTemplatesComponent from './comment-templates.component'
import CommentTemplateViewComponent from './comment-template-view.component'
const CommentsModule = angular.module('cdbl.comments', [

])
  .component('cdblCommentsFormReferredPartnerNotice', ReferredPartnerNoticeComponent)
  .component('cdblCommentTemplates', CommentTemplatesComponent)
  .component('cdblCommentTemplateView', CommentTemplateViewComponent)
  .name

export default CommentsModule
