// (function () {
  'use strict';

  function markedTextarea(
    $window,
    $document,
    $compile,
    Modal,
    SegmentAnalytics,
    CommentService
  ) {
    var link = function (
      $scope,
      element,
      attrs
    ) {
      let rows = 4;
      let autogrowMaxheight = 500;
      if (window.innerWidth < 769) {
        rows = 2;
        autogrowMaxheight = 200;
      }
      var defaultSettings = {
        rows: rows,
        cols: 30,
        maxlength: 5000,
        maxlengthWarning: 4000,
        autogrow: true,
        autogrowMaxheight: autogrowMaxheight,
        placeholder: 'Write your description. They support Markdown!',
        name: 'description',
        // classes for control block of buttons
        controlsClass: 'visible-md-block visible-lg-block',
        required: false
      };
      $scope.config = _.merge({}, defaultSettings, $scope.config);

      if ('maxlength' in attrs) {
        $scope.config.maxlength = parseInt(attrs.maxlength);
      }
      if ('rows' in attrs) {
        $scope.config.rows = parseInt(attrs.rows);
      }
      if ('cols' in attrs) {
        $scope.config.cols = parseInt(attrs.cols);
      }
      if ('required' in attrs) {
        $scope.config.required = true;
      }

      $scope.$textarea = element.find('textarea');
      console.log('markedTextarea > textarea', $scope.$textarea);
      $scope.selection = null;
      $scope.CommentService = CommentService;

      // extract current selection text and start/end offsets
      function getSelection() {
        var e = $scope.$textarea[0];
        return (

          ('selectionStart' in e && function () {
            var l = e.selectionEnd - e.selectionStart;
            var s = {
              start: e.selectionStart,
              end: e.selectionEnd,
              length: l,
              text: e.value.substr(e.selectionStart, l)
            };
            // console.log('markedTextarea > getSelection', s);
            return s;
          }) ||

          /* browser not supported */
          function () {
            return null;
          }
        )();
      }

      function getContent() {
        return $scope.$textarea.val();
      }

      function finalize() {
        // set focus on textarea to continue editing
        $scope.$textarea.focus();

        // update ng-model
        $scope.data = getContent();

        // manualy trigger onChange method as jquery changes to content are not detected
        // $scope.onChange();
      }

      function setSelection(start, end) {
        var e = $scope.$textarea[0];
        return (
          ('selectionStart' in e && function () {
            e.selectionStart = start;
            e.selectionEnd = end;
            return;
          }) ||
          /* browser not supported */
          function () {
            return null;
          }
        )();
      }

      function replaceSelection(text) {
        var e = $scope.$textarea[0];
        return (
          ('selectionStart' in e && function () {
            e.value = e.value.substr(0, e.selectionStart) + text + e.value.substr(e.selectionEnd, e.value.length);
            // Set cursor to the last replacement end
            e.selectionStart = e.value.length;
            return this;
          }) ||
          /* browser not supported */
          function () {
            e.value += text;
            return jQuery(e);
          }
        )();
      }

      function applyBold() {
        var sel = getSelection();
        console.log('markedTextarea > applyBold > selection', sel);
        var prefix = '**';
        var postfix = '**';
        replaceSelection(prefix + sel.text + postfix);
        setSelection(sel.start + prefix.length, sel.end + postfix.length);

        finalize();
      }

      function applyUnordered() {
        var chunk;
        var cursor;
        var selected = getSelection();
        var prefix = '* ';

        if (selected.length === 0) {
          // Insert dummy line if nothing selected
          var isEmpty = getContent().length === 0;
          chunk = 'list text here';
          replaceSelection((isEmpty ? '' : '\n') + prefix + chunk);
          cursor = selected.start + prefix.length + (isEmpty ? 0 : 1);
        } else if (selected.length > 0) {
          if (selected.text.indexOf('\n') < 0) {
            chunk = selected.text;
            replaceSelection(prefix + chunk);
            cursor = selected.start + prefix.length;
          } else {
            var list = [];
            list = selected.text.split('\n');
            chunk = list[0];
            $.each(list, function (k, v) {
              list[k] = prefix + v;
            });

            if (selected.start > 0) {
              replaceSelection('\n\n' + list.join('\n'));
              cursor = selected.start + 4;
            } else {
              replaceSelection(list.join('\n'));
              cursor = selected.start;
            }
          }
        }
        // Set the cursor
        setSelection(cursor, cursor + chunk.length);

        finalize();
      }

      function applyOrdered() {
        var chunk;
        var cursor;
        var selected = getSelection();
        var prefix = '1. ';

        var counter = 1;
        function getNewPrefix() {
          prefix = (counter++) + '. ';
          return prefix;
        }

        if (selected.length === 0) {
          // Insert dummy line if nothing selected
          var isEmpty = getContent().length === 0;
          chunk = 'list text here';
          replaceSelection((isEmpty ? '' : '\n') + getNewPrefix() + chunk);
          cursor = selected.start + prefix.length + (isEmpty ? 0 : 1);
        } else if (selected.length > 0) {
          if (selected.text.indexOf('\n') < 0) {
            chunk = selected.text;
            replaceSelection(getNewPrefix() + chunk);
            cursor = selected.start + prefix.length;
          } else {
            var list = [];
            list = selected.text.split('\n');
            chunk = list[0];
            $.each(list, function (k, v) {
              list[k] = getNewPrefix() + v;
            });

            if (selected.start > 0) {
              replaceSelection('\n\n' + list.join('\n'));
              cursor = selected.start + 4;
            } else {
              replaceSelection(list.join('\n'));
              cursor = selected.start;
            }
          }
        }
        // Set the cursor
        setSelection(cursor, cursor + chunk.length);

        finalize();
      }

      function applyLink() {
        var selected = getSelection();

        var link = '[' + $scope.linkPopup.text + '](' + $scope.linkPopup.link + ')';
        var cursor = selected.start + link.length;

        replaceSelection(link);
        setSelection(cursor, cursor);

        finalize();
      }

      function showHelp() {
        Modal.open(
          'views/directives/markedTextarea.help.modal.html',
          {

          },
          {
            closable: true
          }
        );
      }

      var elLinkPopup;
      $scope.onClick = function (toolId, evt) {
        // force update ng-model with $timeout (safer than $apply)
        console.log('markedTextarea > onClick >', toolId);
        switch (toolId) {
          case 'bold':
            applyBold();
            break;
          case 'unordered-list':
            applyUnordered();
            break;
          case 'ordered-list':
            applyOrdered();
            break;
          case 'link':
            evt.preventDefault();
            evt.stopPropagation();
            $scope.linkPopup.toggle();

            break;
          case 'help':
            showHelp();
            break;
          case 'templates':
            CommentService.openCommentTemplatesModal();
            break;
        }

        // Task object is binded to directive optionally - just for tracking purposes - track only if it is present
        if ($scope.task) {
          SegmentAnalytics.trackMarkdownBarClicked(toolId, $scope.task);
        }
      };

      // $scope.onChange = function () {
      //   if ($scope.config.autogrow) {
      //     // reset inline height
      //     // console.log($scope.$textarea[0].scrollHeight);
      //     // console.log($scope.$textarea[0].style.height);
      //     $scope.$textarea[0].style.height = 'auto';
      //     // calc new height
      //     var height = $scope.$textarea[0].scrollHeight + 2;
      //     if ($scope.config.autogrowMaxheight > 0) {
      //       height = Math.min($scope.config.autogrowMaxheight, height);
      //     }

      //     // set new height
      //     $scope.$textarea[0].style.height = height + 'px';
      //   }
      // };

      $scope.onMouseUp = function () {
        getSelection();
      };

      function init() {
        // $scope.data = 'Sample prefilled text';
        elLinkPopup = element.find('#linkPopup');
        $scope.linkPopup = {
          isVisible: false,
          btnText: 'Insert',
          text: '',
          textPlaceholder: 'Link text',
          link: '',
          linkPlaceholder: 'URL',
          confirm: function () {
            console.log('markedTextarea > linkPopup > confirm');
            if ($scope.linkPopup.text.length === 0 && $scope.linkPopup.link.length > 0) {
              $scope.linkPopup.text = $scope.linkPopup.link;
            }

            if ($scope.linkPopup.text.length > 0 || $scope.linkPopup.link.length > 0) {
              applyLink();
            }

            $scope.linkPopup.hide();
          },
          toggle: function () {
            if ($scope.linkPopup.isVisible) {
              $scope.linkPopup.hide();
            } else {
              $scope.linkPopup.show();
            }
          },
          show: function () {
            $scope.linkPopup.isVisible = true;
            $scope.linkPopup.text = getSelection().text;
            $document.on('click.popover-outside', function (evt) {
              var elPopover = elLinkPopup.siblings('.popover');
              console.log('popup listener', elPopover);
              if (!elPopover.is(evt.target) && elPopover.has(evt.target).length === 0) {
                // console.log('popup click outside > hide', elPopover);
                $scope.linkPopup.hide();
              }
            });
            elLinkPopup.popover('show');
          },
          hide: function () {
            $scope.linkPopup.isVisible = false;
            $scope.linkPopup.text = '';
            $scope.linkPopup.link = '';
            $document.off('click.popover-outside');
            elLinkPopup.popover('hide');
          },
          keyPressHandler: function ($event) {
            // prevent submitting parent form
            if ($event.key === 'Enter' || $event.keyCode === 13) {
              // console.log('markedTextarea > linkPopup > keyPressHandler > enter', $event);
              $event.preventDefault();
              $event.stopPropagation();
              this.confirm();
            }
          }
        };

        var tplLinkPopup = $compile(`
              <div class="field">
                <label for="text">Text</label>
                <input id="text" type="text" ng-model="linkPopup.text" autocomplete="off" placeholder="{{linkPopup.textPlaceholder}}" ng-keypress="linkPopup.keyPressHandler($event)">
              </div>

              <div class="field">
                <label for="link">Link</label>
                <input id="link" type="text" ng-model="linkPopup.link" autocomplete="off" placeholder="{{linkPopup.linkPlaceholder}}" ng-keypress="linkPopup.keyPressHandler($event)">
                <div class="btn btn-primary btn-interactive" ng-click="linkPopup.confirm()">{{linkPopup.btnText}}</div>
              </div>
          `)($scope);

        elLinkPopup.popover({
          title: 'Insert link',
          content: tplLinkPopup,
          trigger: 'manual',
          html: true
        });

        // $scope.$watch('data', function (newVal, oldVal) {
        //   if (newVal !== oldVal) {
        //     $scope.onChange();
        //   }
        // });
      }

      init();

      $scope.$on('$destroy', function () {
        elLinkPopup.popover('destroy');
      });
    };

    return {
      restrict: 'E',
      scope: {
        data: '=',
        submitOnEnter: '=?',
        config: '=?',
        task: '<?'
      },
      templateUrl: require('../../views/directives/markedTextarea.directive.html'),
      link: link
    };
  }

  app.directive('markedTextarea', [
    '$window',
    '$document',
    '$compile',
    'Modal',
    'SegmentAnalytics',
    'CommentService',
    markedTextarea
  ]);
// })();
