import defaultModalTemplateUrl from './default-modal.html'

const ModalService = class ModalService {
  constructor ($q, UtilService) {
    'ngInject'
    this.$q = $q
    this.UtilService = UtilService
    this.modal = null
    this.onUpdateCallback = angular.noop

    this.defaultConfig = {
      templateUrl: defaultModalTemplateUrl,
      title: false,
      subtitle: false,
      body: false,
      closeOnBackdrop: false,
      closeIcon: true,
      closeButton: false,
      backdrop: true,
      layout: 'default', // default | center
      size: 'default',   // default | small | large
      class: 'default',
      transition: 'fade', // TODO
      position: 'center center', // TODO
      actions: []
    }

    this.modalQueue = []

    // this.scrollPosition = null
  }

  get isOpened () {
    return Boolean(this.modal)
  }

  getModal () {
    return this.modal
  }

  clear () {
    this.modalQueue = []
  }

  open (modalConfig = {}) {
    console.log('ModalService > open', modalConfig)
    // remember scroll position
    // this.scrollPosition = this.UtilService.getElement('window').pageYOffset
    // console.log(this.scrollPosition)

    // let scrollElement = this.UtilService.getElement('.app-wrapper') ? this.UtilService.getElement('.app-wrapper') : this.UtilService.getElement('.cdbl-layout__outer-wrapper')
    // scrollElement.scrollTop = this.scrollPosition
    let config = this.UtilService.merge({}, this.defaultConfig, modalConfig)


    if (config.closeButton) {
      // config.actions.push({
      //   label: 'Close',
      //   class: 'primary',
      //   callback: () => {
      //     this.close()
      //   }
      // })
      config.resolveButton = {
        label: `Close`,
        class: 'button-primary',
      }
    }

    // make sure that only one template system is set
    if (config.template) {
      config.templateUrl = false
    } else if (config.templateUrl) {
      config.template = false
    }

    if (!config.deferred) {
      config.deferred = this.$q.defer()
      config.reject = (arg) => {
        this.modal.deferred.reject(arg)
      }
      config.resolve = (arg) => {
        this.modal.deferred.resolve(arg)
      }
    }

    if (this.modal === null) {
      this.modal = config
      this.UtilService.addBodyClass('cdbl-modal-is-opened')
    } else {
      this.modalQueue.push(config)
    }

    return config.deferred.promise
  }

  // Should be used instead of open method when its a chainable modal
  openNext (modalConfig) {
    this.open(modalConfig)
    this.close()
  }

  update (modalConfig) {
    this.modal = Object.extend(this.modal, modalConfig)
  }

  close () {
    console.log('ModalService > close', this.modal)
    this.UtilService.removeBodyClass('cdbl-modal-is-opened')
    // this.UtilService.getElement('window').scrollTo(0, this.scrollPosition)
    // this.scrollPosition = null

    this.modal = null
    if (this.modalQueue.length >= 1) {
      const currentModal = this.modalQueue.shift()
      this.open(currentModal)
    }
  }


  generateConfig (type = 'default', customConfig = {}) {
    let config = {
      closeOnBackdrop: false,
      closeIcon: true,
      resolveButton: {
        label: `Close`,
        class: 'button-primary',
      }
    }

    if (type === 'confirmation') {
      return this.UtilService.merge({}, config, {
        title: 'Please confirm',
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        rejectButton: {
          label: `Reject`,
          class: 'button-outline',
        },
        resolveButton: {
          label: `Resolve`,
          class: 'button-primary',
        }
      }, customConfig)
    } else if (type === 'success') {
      return this.UtilService.merge({}, config, {
        closeOnBackdrop: true,
        closeIcon: true,
        layout: 'center',
        resolveButton: {
          label: `Resolve`,
          class: 'button-primary',
        }
      }, customConfig)
    } else if (type === 'danger') {
      return this.UtilService.merge({}, config, {
        closeOnBackdrop: false,
        closeIcon: true,
        layout: 'center',
        rejectButton: {
          label: `Reject`,
          class: 'button-outline',
        },
        resolveButton: {
          label: `Resolve`,
          class: 'button-reject',
        }
      }, customConfig)
    }

    return this.UtilService.merge({}, config, customConfig)
  }


  resolve (autoClose = true, arg) {
    console.log('resolve', autoClose, arg)
    this.modal.deferred.resolve(arg)
    if (autoClose) {
      this.close()
    }
  }

  reject (autoClose = true) {
    this.modal.deferred.reject()
    if (autoClose) {
      this.close()
    }
  }
}
export default ModalService


/**
* How to use it in controllers

ModalService.open({
  template: `
    <a ng-click="$ctrl.modal.customCallback()">Modal content: {{$ctrl.modal.message}}</a> <a ng-click="$ctrl.modalClose()">Close</a>
  `,
  message: `hello from modal`,
  customCallback: () => {
    console.log('modal customCallback');
  }
});
 */

